<template>
  <div class="d-inline-block mx-auto">
    <v-progress-circular
      :value="value"
      class="mb-4"
      :color="color"
      rotate="-90"
      size="88"
      width="4"
    >
      <span v-if="type == 1" class="title font-weight-bold">
        <span class="grey--text text--darken-1" v-text="value" />
        <span class="grey--text text--darken-1 font-weight-thin">%</span>
      </span>
      <span v-if="type == 2" class="title font-weight-bold">
        <span class="grey--text text--darken-1" v-text="subTitle" />
        <span class="grey--text text--darken-1 font-weight-thin"></span>
      </span>
    </v-progress-circular>

    <Subtitle
      :title="title"
      align="center"
      class="font-weight-bold grey--text text--darken-1"
    />
  </div>
</template>

<script>
import Subtitle from "./Subtitle.vue";
export default {
  components: { Subtitle },
  name: "BaseCircularProgress",

  inheritAttrs: false,

  props: {
    title: String,
    value: [Number, String],
    color: {
      type: String,
      default: "primary",
    },
    type: {
      type: Number,
      defaut: 1,
    },
    subTitle: {
      type: String,
      default: "",
    },
  },
};
</script>
