<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row :justify="justify" no-gutters>
        <v-col v-if="icon" :class="`text-${align}`" cols="12" class="mb-4">
          <Icon color="color">
            {{ icon }}
          </Icon>
        </v-col>

        <v-col v-if="title || subtitle" :cols="callout ? 9 : 12">
          <Subtitle v-if="subtitle" :title="subtitle" space="1" />
          <Title :title="title" class="text-uppercase" space="1" />
          <Divider :color="color" />

          <Body v-if="text || $slots.default" :text="text" space="6" >
            <slot />
          </Body>
        </v-col>

        <v-col v-if="callout" cols="2">
          <div
            class="text-h2 grey--text text--lighten-3 font-weight-bold pr-8"
            v-text="callout"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
// Mixins
import Heading from "@/mixins/heading";
// import Icon from "./Icon.vue";
import Subtitle from "./Subtitle.vue";
import Title from "./Title.vue";
import Divider from "./Divider.vue";
import Body from "./Body.vue";

export default {
  name: "CustomInfoCard",
  mixins: [Heading],
  props: {
    dark: Boolean,
    callout: String,
    color: {
      type: String,
      default: "primary",
    },
    icon: String,
    subtitle: String,
    text: String,
    title: String,
  },
  components: { Subtitle, Title, Divider, Body },
};
</script>
