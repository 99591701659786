<template>
  <Section class="text-center white" id="pricing" light>
    <SectionHeading size="text-h3" :title="SectionHeadingTitle">
      <Body> {{ subHeadingTitle }} </Body>
    </SectionHeading>
    <div class="pa-1" />

    <v-container>
      <v-row>
        <v-col v-for="(plan, i) in plans" :key="i" cols="12" md="4">
          <PriceCard v-bind="plan" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <Btn color="#1976D2" to="/tradingbot/" class="white--text">
            เลือกโรบอทกันเลย
          </Btn>
        </v-col>
      </v-row>
    </v-container>
  </Section>
</template>

<script>
import PriceCard from "../subcomponents/PriceCard.vue";
import Section from "../subcomponents/Section.vue";
import SectionHeading from "../subcomponents/SectionHeading.vue";
import Btn from "../subcomponents/Btn.vue";

export default {
  name: "SectionPricingPlan",

  components: {
    PriceCard,
    Section,
    SectionHeading,
    Btn,
  },

  provide: {
    heading: { align: "center" },
  },

  data: () => ({
    internalPlans: [
      {
        title: "ฟรี 3 เดือน",
        subtitle: "ทดลองใช้งานและจำกัดมูลค่าพอร์ท",
        price: 0,
        interval: 3,
        intervalText: "3 เดือน",
        intervalUnit: "m",
        redTextSaving: false,
        features: [
          // "Free",
          "ระยะเวลา 3 เดือน",
          "ไม่จำกัดมูลค่าพอร์ท",
          "โรบอทเทรด 1 ตัว (ต้องเลือก)",
          "Price Forecasting",
          "Pattern Detection",
          "Scanner Technical Analysis",
        ],
      },
      {
        title: "1 เดือน",
        subtitle: "ใช้งาน 1 เดือนและไม่จำกัดมูลค่าพอร์ท",
        price: 650,
        interval: 1,
        intervalText: "1 เดือน",
        intervalUnit: "m",
        redTextSaving: false,
        features: [
          "Save 0%",
          "ไม่จำกัดมูลค่าพอร์ท",
          "โรบอทเทรด 1 ตัว (ต้องเลือก)",
          "Price Forecasting",
          "Pattern Detection",
          "Scanner Technical Analysis",
        ],
      },
      {
        title: "3 เดือน",
        subtitle: "ใช้งาน 3 เดือนและไม่จำกัดมูลค่าพอร์ท",
        price: 1800,
        interval: 3,
        intervalText: "3 เดือน",
        intervalUnit: "m",
        redTextSaving: true,
        features: [
          "Save 8%",
          "ไม่จำกัดมูลค่าพอร์ท",
          "โรบอทเทรด 1 ตัว (ต้องเลือก)",
          "Price Forecasting",
          "Pattern Detection",
          "Scanner Technical Analysis",
        ],
      },
      {
        title: "6 เดือน",
        subtitle: "ใช้งาน 6 เดือนและไม่จำกัดมูลค่าพอร์ท",
        price: 3250,
        interval: 6,
        intervalText: "6 เดือน",
        intervalUnit: "m",
        redTextSaving: true,
        features: [
          "Save 17%",
          "ไม่จำกัดมูลค่าพอร์ท",
          "โรบอทเทรด 1 ตัว (ต้องเลือก)",
          "Price Forecasting",
          "Pattern Detection",
          "Scanner Technical Analysis",
        ],
      },
      {
        title: "1 ปี",
        subtitle: "ใช้งาน 1 ปีและไม่จำกัดมูลค่าพอร์ท",
        price: 4999,
        interval: 12,
        intervalText: "1 ปี",
        intervalUnit: "y",
        redTextSaving: true,
        features: [
          "Save 36%",
          "ไม่จำกัดมูลค่าพอร์ท",
          "โรบอทเทรด 1 ตัว (ต้องเลือก)",
          "Price Forecasting",
          "Pattern Detection",
          "Scanner Technical Analysis",
        ],
      },
    ],
    SectionHeadingTitle: "ค่าบริการ",
    subHeadingTitle:
      "ในปัจจุบันฟีเจอร์ของเราสามารถใช้งานได้ฟรีทั้งหมด ยกเว้นโรบอทเทรดเท่านั้น ที่จะคิดค่าบริการรายเดือนหรือมากกว่านั้นแต่ราคาจะถูกลง",
  }),

  computed: {
    plans() {
      return this.internalPlans.map((plan) => {
        return {
          ...plan,
          // features: this.features,
          title: plan.title,
          subtitle: plan.subtitle,
        };
      });
    },
  },
};
</script>
