<template>
  <Section id="project" light class="mx-auto white">
    <v-container style="max-width: 1024px">
      <Subheading
        title="โรบอทเทรดของเราทำงานยังไง ?"
        tag="h1"
        space="12"
        class="grey--text text--darken-1"
      />

      <Img
        :height="$vuetify.breakpoint.mdAndUp ? 250 : 150"
        :src="require(`@/assets/commercial/ml-1.png`)"
        class="mb-6 mb-md-12"
        contain
      />

      <v-row>
        <v-col cols="12" md="9">
          <Title title="" />

          <Body space="12" class="grey--text text--darken-1">
            สัญญาณการเทรดของโรบอทเราจะมาจาก Deep Learning Model
            ที่จะนำราคาย้อนหลังในช่วงระยะเวลาหนึ่ง ที่ข้อมูลแท่งเทียน Open High
            Low Close Volume จากนั้นจะถูกส่งเข้าไปยังโมเดล
            และโมเดลจะทำหน้าที่ตอบว่าให้ เปิดออเดอร์ ถือเอาไว้ หรือปิดออเดอร์
            พร้อมทั้งทิศทางของราคา ถ้าโมเดลตอบว่าขึ้นก็เทรด Long
            ถ้าตอบว่าลงก็เทรด Short ซึ่งสัญญาณที่ได้จะมีค่าความมั่นใจให้มาด้วย
            โรบอทเทรดของเราจะเปิดออเดอร์ก็ต่อเมื่อค่าความมั่นใจมากกว่า 90%
            เท่านั้น อีกทั้งโมเดลยังมีขนาดเล็ก ทำงานได้ไว
            ทันท่วงทีต่อราคาที่เปลี่ยนแปลง เพื่อลด Slippage (ความคลาดเคลื่อนจากราคาที่ต้องการ) 
            ในระหว่างการเปิด/ปิดออเดอร์
          </Body>

          <v-row  justify="center" class="text-center">
            <v-col
              v-for="[type, value, title, subTitle] of values"
              :key="title"
              cols="6"
              md="3"
            >
              <CircularProgress
                color="green"
                v-bind="{ type, value, title, subTitle }"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="3">
          <div class="caption">
            <Title
              title="ข้อมูลโมเดล"
              space="2"
              class="grey--text text--darken-1"
            />

            <v-divider class="grey" />

            <div class="grey--text text--darken-1">
              <template v-for="([title, sub], i) of info">
                <v-row :key="title" class="mx-0 my-3" justify="space-between">
                  <span v-text="title" />

                  <strong v-text="sub" />
                </v-row>

                <v-divider class="grey" :key="`divider-${i}`" />
              </template>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </Section>
</template>

<script>
import Body from "../subcomponents/Body.vue";
import CircularProgress from "../subcomponents/CircularProgress.vue";
import Img from "../subcomponents/Img.vue";
import Section from "../subcomponents/Section.vue";
import Subheading from "../subcomponents/Subheading.vue";
import Title from "../subcomponents/Title.vue";

export default {
  components: { Subheading, Img, Title, Body, Section, CircularProgress },

  name: "Project",

  data: () => ({
    icons: ["mdi-twitter", "mdi-facebook", "mdi-github", "mdi-snapchat"],
    info: [
      ["ขนาดตัวแปร", "~150,000"],
      ["ระยะเวลาเทรน", "24 เดือน"],
      ["กรอบเวลา", "15m, 1h, 4h"],
      ["การจำแนกประเภท", "6 Class"],
    ],
    values: [
      [1, 90, "ค่าความมั่นใจ ", ""],
      [1, 100, "การคำนวณ", ""],
      [2, 40, "ความเร็ว (Sec)", "0.4"],
      //   [65, "Marketing"],
    ],
  }),
};
</script>
