<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/commercial/main-hero-1.png')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <Subtitle
            weight="regular"
            title="โรบอทเทรด Cryptocurrency ด้วย A.I. Deep Learning"
            :space="2"
          />

          <Heading
            size="text-h2"
            title="CRYPTO-MATION"
            weight="medium"
            :space="2"
          />

          <Body>
            โรบอทและเครื่องมือช่วยเทรดในตลาด Cryptocurrency ประสิทธิภาพสูง
            <br />
            บอกทุกค่าสถิติ ผลกำไรและความเสี่ยง ไม่มีหมกเม็ด <br />
            ช่วยให้คุณทำกำไรได้โดยไม่ต้องเฝ้าจอ
          </Body>

          <div
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'flex-column align-start'
                : 'align-center'
            "
            class="d-flex flex-wrap"
          >
            <Btn
              color="#1976D2"
              class="white--text"
              v-on:click="$vuetify.goTo('#features')"
            >
              ดูเพิ่มเติม
            </Btn>

            <span class="font-weight-bold ml-6 mr-4 my-4">หรือ</span>

            <Btn
              :ripple="false"
              color="success"
              to="/tradingbot/"
              outlined
              class="white--text"
            >
              เริ่มต้นใช้งาน
            </Btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
import Subtitle from "../subcomponents/Subtitle.vue";
import Heading from "../subcomponents/Heading.vue";
import Body from "../subcomponents/Body.vue";
import Btn from "../subcomponents/Btn.vue";

export default {
  name: "SectionHero",
  provide: {
    theme: { isDark: false },
  },
  data: () => ({
    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
  }),
  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";
      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
  components: { Subtitle, Heading, Body, Btn },
};
</script>
