<template>
  <Section id="features" light class="grey lighten-4">
    <SectionHeading :title="title1" size="text-h3">
      <Body class="cm-body-subtitle">
        คุณลักษณะของแอปฯ Crypto-Mation ทั้งหมด <br />
        ที่เป็นเครื่องมือช่วยเทรดอันชาญฉลาด <br />
        ไปจนถึงโรบอทเทรดอัตโนมัติแบบไม่ต้องเฝ้ากราฟ
      </Body>
    </SectionHeading>

    <v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <AvatarCard v-bind="feature" align="left" horizontal>
            {{ feature.subTitle }}
          </AvatarCard>
        </v-col>
      </v-row>
    </v-container>
  </Section>
</template>

<script>
import Section from "../subcomponents/Section.vue";
import SectionHeading from "../subcomponents/SectionHeading.vue";
import AvatarCard from "../subcomponents/AvatarCard.vue";
export default {
  name: "SectionThemeFeatures",
  data: () => ({
    title1: "ความสามารถ",
    subTitle1: "",
    features: [
      {
        title: "Trading Robot",
        icon: "mdi-star-circle",
        subTitle:
          "คุณลักษณะของแอปฯ Crypto-Mation ทั้งหมด ที่เป็นเครื่องมือช่วยเทรดอันชาญฉลาด ไปจนถึงโรบอทเทรดอัตโนมัติแบบไม่ต้องเฝ้ากราฟ",
        dark: true,
        color: "#42A5F6",
        tags: ['Android', 'Website'],
        tagColors: ['green', 'blue'],
      },
      {
        title: "Price Forecasting",
        icon: "mdi-chart-line",
        subTitle:
          "จะสามารถบอกแนวโน้ม UpTrend / DownTrend และค่าสถิติในอดีต โดยคำนวณมาจาก Deep Learning Model ที่ถูกสร้างขึ้นมาเป็นอย่างดี",
        dark: true,
        color: "#42A5F6",
        tags: ['Android', 'iOS'],
        tagColors: ['green', 'grey'],
      },
      {
        title: "Pattern Detection",
        icon: "mdi-chart-waterfall",
        subTitle:
          "สามารถตรวจจับ Pattern ของราคาแท่งเทียนได้ อย่างทันท่วงที หลากหลายรูปแบบ จะทำให้คุณวิเคราะห์ราคาได้ง่ายขึ้น และสร้างผลกำไรให้คุณ เช่น Ascending Triangle, Descending Triangle, Symmetrical Triangle, Head and Shoulders, Doubble Bottom, Doubble Top, Cup",
        dark: true,
        color: "#42A5F6",
        tags: ['Android', 'iOS'],
        tagColors: ['green', 'grey'],
      },
      {
        title: "Scanner Technical Analysis",
        icon: "mdi-radar",
        subTitle:
          "เป็นบริการค้นหาสัญญาณที่เกิดขึ้นจาก Technical Analysis มากกว่า 30 รูปแบบ และหลากหลาย TimeFrame",
        dark: true,
        color: "#42A5F6",
        tags: ['Android', 'iOS'],
        tagColors: ['green', 'grey'],
      },
    ],
  }),
  components: { Section, SectionHeading, AvatarCard },
};
</script>

<style scoped>

</style>
