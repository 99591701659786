import { httpClientGet } from "@/services/api/axios";

export async function getCommerciaBotStats(crypto_id) {
  try {
    // const { data } = await axiosInstance.get(`/robot/detail?id=MC6x_future_BTCUSDT`)
    return await httpClientGet(`/commercial/botstats?id=${crypto_id}`);
  } catch (error) {
    return {};
  }
}
