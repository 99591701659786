<template>
  <Section id="howto" light class="white">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <SectionHeading
            align="left"
            :subtitle="subTitle1"
            tag="h3"
            :title="title1"
            class="black--text"
            color="black"
            size="text-h3"
          >
            <span class="black--text">
              โรบอทเทรดสามารถใช้งานได้บน Android และ Website
              <br />
              ความสามารถอื่นๆ สามารถใช้งานได้บน Android และ iOS
              <br />
              คลิกหรือสแกนเพื่อใช้งานได้เลย
            </span>
          </SectionHeading>

          <Btn color="#1976D2" class="white--text" to="/tradingbot/">ใช้งานบนเว็บไซต์</Btn>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column align-center justify-center"
        >
          <!-- class="ml-auto" -->
          <div class="d-flex justify-center">
            <div class="mx-4">
              <a
                href="https://apps.apple.com/th/app/crypto-mation/id1600835933"
                target="_blank"
              >
                <v-img
                  class="ma-2"
                  :width="ImageWidth"
                  :src="require('@/assets/commercial/applestore.svg')"
                  contain
                />
                <v-img
                  :src="require('@/assets/commercial/ios-ai-mation.png')"
                  :width="ImageWidth"
                  class="ma-auto"
                />
              </a>
            </div>
            <div class="mx-4">
              <a
                href="https://play.google.com/store/apps/details?id=com.aimationplus.cryptomation"
                target="_blank"
              >
                <v-img
                  class="ma-2"
                  :width="ImageWidth"
                  :src="require('@/assets/commercial/androidstore.svg')"
                  contain
                />
                <v-img
                  :src="require('@/assets/commercial/android-ai-mation.png')"
                  :width="ImageWidth"
                  class="ma-auto"
                />
              </a>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </Section>
</template>

<script>
// import Img from "../subcomponents/Img.vue";
import Section from "../subcomponents/Section.vue";
import SectionHeading from "../subcomponents/SectionHeading.vue";
import Btn from "../subcomponents/Btn.vue";

export default {
  components: { Section, SectionHeading, Btn },
  name: "SectionMobile",

  data: () => ({
    title1: "บนเว็บไซต์และแอปฯ",
    subTitle1: "ดาวน์โหลด",
    appStoreUrl: "https://apps.apple.com/th/app/crypto-mation/id1600835933",
    googlePlayStoreUrl:
      "https://play.google.com/store/apps/details?id=com.aimationplus.cryptomation",
  }),
  methods: {},

  computed: {
    ImageWidth() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return "125";
      }
      return "200";
    },
  },

  mounted() {},
};
</script>
