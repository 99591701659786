<template>
  <v-container class="extra-padding">
    <v-row>
      <v-col cols="12">
        <Subtitle title="" space="3" />
        <div class="display-1 black--text">{{ projectData.title }}</div>

        <Divider color="primary" />
        <!-- <Title class="" :title="projectData.title" tag="div" /> -->
        <Body>
          <div v-if="projectData.index == 0" class="body-1">
            <Body class="grey--text text--darken-1">
              {{ projectData.text }}
            </Body>

            <center>
              <div class="my-2">
                <LazyYoutube
                    ref="lazyVideo"
                    src="https://www.youtube.com/embed/3x3gsnEGyIo"
                />
              </div>
            </center>
          </div>
          <div v-if="projectData.index == 1" class="body-1">
            <Body space="5" class="grey--text text--darken-1">
              {{ projectData.text }}
            </Body>
            <v-img
              :src="require('@/assets/commercial/futures_setting.png')"
              class="mb-5"
            />

            <Body class="grey--text text--darken-1" space="5">{{
              projectData.subtitle
            }}</Body>

            <center>
              <div class="my-2">
                <LazyYoutube
                    ref="lazyVideo"
                    src="https://www.youtube.com/embed/al5dZ2Me_Bw"
                />
              </div>
            </center>
          </div>
          <div v-if="projectData.index == 2" class="body-1">
            <!-- การเติมเงินเข้าบัญชี -->
            <Body class="grey--text text--darken-1">{{ projectData.text }}</Body>
            <center>
              <div class="my-2">
                <LazyYoutube
                    ref="lazyVideo"
                    src="https://www.youtube.com/embed/al5dZ2Me_Bw"
                />
              </div>
            </center>
          </div>
          <div v-if="projectData.index == 3" class="body-1">
            <Body class="grey--text text--darken-1">{{ projectData.text }}</Body>
            <center>
              <div class="my-2">
                <LazyYoutube
                    ref="lazyVideo"
                    src="https://www.youtube.com/embed/YYqXtAQ-w9A"
                />
              </div>
            </center>
          </div>
          <center v-if="projectData.goTo !== '' && projectData.goTo !== undefined">
            <router-link
              :to="projectData.goTo"
              style="text-decoration: none !important"
            >
              <Body style="color: #1976d2 !important" class="text-center"
                >ดูเพิ่มเติม</Body
              >
            </router-link>
          </center>
        </Body>

        <!-- <Btn class="mb-12"> Discover More </Btn> -->

        <!-- <v-row>
          <v-col v-for="(text, i) in marketing" :key="i" cols="12" md="6">
            <ListItem :text="text" />
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Subheading from "../subcomponents/Subheading.vue";
// import Title from "../subcomponents/Title.vue";
import Subtitle from "../subcomponents/Subtitle.vue";
import Body from "../subcomponents/Body.vue";
import Divider from "../subcomponents/Divider.vue";
// import Btn from "../subcomponents/Btn.vue";
import { LazyYoutube } from "vue-lazytube";

export default {
  name: "CustomProjects",
  data: () => ({
  }),
  props: {
    projectData: {
      type: Object,
      default: Object,
    },
  },
  computed: {
    VideoWidth() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return "280";
      }
      return "560";
    },
    VideoHeight() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return "200";
      }
      return "315";
    },
  },
  components: { Body, Divider, Subtitle, LazyYoutube},
};
</script>
