<template>
  <div :class="classes" class="base-section-heading">
    <Avatar
      v-if="icon"
      :icon="icon"
      :outlined="outlined"
      class="mb-4"
      color="primary"
      dark
    />

    <Subtitle v-if="subtitle" :title="subtitle" space="1" tag="h2" />

    <SubHeading
      v-if="title"
      :align="align"
      :title="title"
      class="text-uppercase"
      space="2"
      :size="size"
    />

    <Divider color="#1976D2" />

    <Body v-if="$slots.default || text" class="mx-auto" max-width="700" >
      <slot v-if="$slots.default" />

      <template v-else>
        {{ text }}
      </template>
    </Body>
  </div>
</template>

<script>
// Mixins
import Heading from "@/mixins/heading";
import Avatar from "./Avatar.vue";
import Subtitle from "./Subtitle.vue";
import SubHeading from "./Subheading.vue";
import Divider from "./Divider.vue";
import Body from "./Body.vue";

export default {
  name: "BaseSectionHeading",
  components: {
    Avatar,
    Subtitle,
    SubHeading,
    Divider,
    Body,
  },
  mixins: [Heading],

  props: {
    align: {
      type: String,
      default: "center",
    },
    color: {
      type: String,
      default: "primary",
    },
    icon: String,
    outlined: Boolean,
    space: {
      type: [Number, String],
      default: 12,
    },
    subtitle: String,
    text: String,
    title: String,
    size: {
      type: String,
      default: "text-h5",
    },
  },

  computed: {
    classes() {
      return [`text-${this.align}`, `mb-${this.space}`];
    },
  },
};
</script>
