<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <Hero />
        
        <ThemeFeatures />
        <Project />
        <InfoGraph />
        <Mobile />
        <RecentWorks />
        <PricingPlan />
        <Features />
        <AboutOurProduct />
        <!--
          <ProFeatures /> 
          <WeHelpYourSuccess />
          
          <Marketing />
          
        
          <QandA /> -->

        <!-- <component
            :is="`section-${section}`"
            v-for="section in sections"
            :key="section"
          /> -->
      </v-col>
    </v-row>
  </section>
</template>

<script>
import Hero from "../sections/Hero.vue";
import ThemeFeatures from "../sections/ThemeFeatures.vue";
import Mobile from "../sections/Mobile.vue";
import Features from "../sections/Features.vue";
// import QandA from "../sections/QandA.vue";
import PricingPlan from "../sections/PricingPlan.vue";
import InfoGraph from "../sections/InfoGraph.vue";
import RecentWorks from "../sections/RecentWorks.vue";
import AboutOurProduct from "../sections/AboutOurProduct.vue";
import Project from "../sections/Project.vue";
// import WeHelpYourSuccess from "../sections/WeHelpYourSuccess.vue";
// import Marketing from "../sections/Marketing.vue";

export default {
  name: "HomeView",
  components: {
    Hero,
    ThemeFeatures,
    Mobile,
    Features,
    PricingPlan,
    InfoGraph,
    RecentWorks,
    AboutOurProduct,
    Project,
  },

  props: {
    id: {
      type: String,
      default: "view",
    },
  },

  data: () => ({ sections: [] }),
};
</script>
